.image-rotator {
    position: relative;
    display: inline-block;
    padding: 2px;
    border-radius: 50%;
    transition: border-color 0.2s ease;
    border: 5px solid transparent;
}



.image-rotator:hover {
    border-color: blue;
}


.rotatable-image {
    display: block;
    border-radius: 50%;
    user-select: none;
    pointer-events: all;
}

.board-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: gray;
  color: white;
  text-align: center;
  padding: 10px 0;
  z-index: 10;
}

.board-footer nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
}

.board-footer nav ul li {
  margin: 0 15px;
}

.board-footer nav ul li a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.board-footer nav ul li a:hover {
  text-decoration: underline;
}

.report-accident-modal {
  z-index: 4000;
}

.element-details-modal {
  z-index: 4000;
}